<template>
  <div class="common_table">
    <a-table 
    v-if="select"
    :row-key="$route.meta.keyword || 'id'" 
    :columns="columns" 
    :data="tableData" 
    :row-selection="rowSelection"
    :pagination="false"
    :bordered="false"  
    :stripe="true"
    :selected-keys="checkedList"
    @selectAll="selectAllClick"
    @selectionChange="selectionChange">
      <template #status="{ record }">
        <span :style="{color: record.color}">{{ record.statusName }}</span>
      </template>
      <template #title="{ record, column }">
        <span class="pointer" @click="eventClick(record)">
           {{ record[column.dataIndex] }}
        </span>
      </template>
      <template #option="{ record }">
         <slot name="options" :record="record"></slot>
      </template>
      <template #cell="{ rowIndex }">{{ rowIndex + 1 }}</template> 
    </a-table>
    <a-table 
    v-else
    :loading="loading || false"
    :columns="columns" 
    :data="tableData" 
    :bordered="false"   
    :stripe="true"
    :pagination="false">
      <template #title="{ record, column }">
        <span class="pointer" @click="eventClick(record)">
          {{ record[column.dataIndex] }}
        </span>
      </template>
      <template #status="{ record }">
        <span :style="{color: record.color}">{{ record.statusName }}</span>
      </template> 
      <template #option="{ record }">
        <slot name="options" :record="record"></slot>
      </template>
      <template #cell="{ rowIndex }">{{ rowIndex + 1 }}</template> 
    </a-table>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, getCurrentInstance, watch } from 'vue'
import { useRoute } from 'vue-router';
export default {
  props: ['columns', 'tableData', 'current', 'select', 'checked', 'indeterminate', 'loading', 'clearC'],
  setup(props, context) {
    let width = ref(window.innerWidth);
    let visible = ref(false);
    let visible1 = ref(false);
    let detailId = ref(null);
    let deleteId = ref(null);
    let route = useRoute();
    let checkedList = ref([])
    const rowSelection = ref({
      type: 'checkbox',
      showCheckedAll: true,
      onlyCurrent: false,
    });
    const { appContext: { config : { globalProperties } } } = getCurrentInstance();
    const scroll = ref({
      x: 1200,
      minWidth: 1200
    })

    watch([() => props.checked, () => props.clearC], ([n, o], [n2, o2]) => {
      if(n && n != o) {
        selectAllClick(true)
      }else {
        if(!props.indeterminate) selectAllClick(false)
      }
      if(n2) {
        if(n2 && !props.indeterminate && !props.checked)checkedList.value = [];
      }
    })

    function deleteClick(id) {
      visible.value = true;
      deleteId.value = id;
    }

    function closeClick(status) { 
      if(!status) {
        visible.value = false;
        deleteId.value = null;
      }else {
        globalProperties.$API.DeleteReport({ 
          id: deleteId.value
        }).then(res => {
          if(res.data.code == 200) {
            globalProperties.$message.success('删除成功');
            visible.value = false;
            deleteId.value = null;
            context.emit('delete', 1)
          }else {
            globalProperties.$message.error(res.data.msg || '操作失败')
          }
        })
      }
    }

    function editClick() {
      visible1.value = false;
      detailId.value = null;
      context.emit('edit', true)
    }

    function selectAllClick(checked) {
      if(checked) props.tableData.forEach(element => {
        if(!element.disabled && checkedList.value.filter(item => { return element.standard_number == item  || element.id == item }).length == 0) checkedList.value.push(element.standard_number || element.id)
      });
      else checkedList.value = []
      context.emit('checkChange', checked ? { type: 2, data: checkedList.value } : { type: 0, data: [] })
    }

    function selectionChange(rowKeys) {
      checkedList.value = rowKeys;
      let data = props.tableData.filter(item => { return !item.disabled})
      if(checkedList.value.length > 0) {
        if(data.length == checkedList.value.length) {
          context.emit('checkChange', { type: 2, data: checkedList.value })
        }else context.emit('checkChange', { type: 1, data: checkedList.value })
      }else {
        context.emit('checkChange', { type: 0, data: [] })
      }
      
      
    }

    function eventClick(record) {
      window.open(!record.standard_number ?
        `/${ route.meta.detail }/${ record.id }` :
        `/${ route.meta.detail }?number=${ record.standard_number }`)
    }

    return {
      scroll,
      width,
      visible,
      visible1,
      detailId,
      deleteId,
      rowSelection,
      checkedList,
      deleteClick,
      closeClick,
      editClick,
      selectAllClick,
      selectionChange,
      eventClick
    }
  }
}
</script>

<style lang='scss'>
  .common_table {
    .pointer {
      font-size: 14px;
      color: #1d2129;

      &:hover {
        color: #0956A8;
        font-weight: 600;
      }
    }

    .arco-table-th-title {
      font-weight: 600;
    }

    .arco-table-td {
      height: 22px;
      line-height: 22px;
      border-bottom: none;
    }

    .arco-table-layout-fixed .arco-table-element {
      table-layout: auto;
    }

    .arco-table-td-content {
      .arco-btn {
        padding: 0;
        line-height: 20px;
        height: 20px;
        margin-right: 7px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>