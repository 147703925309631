import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "type_list" }
const _hoisted_2 = ["onClick"]

import { ref, getCurrentInstance, watch } from 'vue'
  import { useRouter, useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import { CONST } from '@/types/const'
  
export default /*@__PURE__*/_defineComponent({
  __name: 'TypeList',
  props: {
    list: {},
    keyName: {}
  },
  emits: ['tabEvent'],
  setup(__props: any, { emit: __emit }) {

  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);

  const props = __props
  const emit = __emit
  const router = useRouter()
  const route = useRoute()
  const store = useStore()

  let tabIndex = ref(props.keyName || props.list[0].value)

  watch(() => props.keyName, (n, o) => {
    if(n && n!= o) {
      tabIndex.value = n;
    }
  })

  watch(() => route.query.type, (n, o) => {
    if(n && n!= o) {
      tabIndex.value = n;
      let data = CONST.commonType.userCenter.filter((item: any) => { return item.value == n })[0];
      eventClick(data)
    }
  }, {
    immediate: true
  })

  function eventClick(item: any) {
    if(item.btn) {
      if(item.btn == 'outLogin') outLogin()
    }else if(item.link) router.push(`/${ item.link }`)
    else {
      tabIndex.value = item.value;
      emit('tabEvent', item)
    }
  }

  function outLogin() {
    globalProperties.$API.OutLogin().then((res: any) => {
        router.replace('/');
        store.commit('login_type', 0)
      })
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.list, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['type_item pointer', _unref(tabIndex) == item.value ? 'active' : '']),
        onClick: ($event: any) => (eventClick(item)),
        key: item.value
      }, _toDisplayString(item.label), 11, _hoisted_2))
    }), 128))
  ]))
}
}

})