import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "satisfactionSurvey" }
const _hoisted_2 = { class: "right" }
const _hoisted_3 = { class: "top_title" }
const _hoisted_4 = ["innerHTML"]

import TypeList from '@/components/TypeList.vue';
  import CommonTable from '@/components/CommonTable.vue';
  import { onMounted, ref, getCurrentInstance } from 'vue';
  import { CONST } from '@/types/const'
  import { useStore } from 'vuex';
import router from '@/router';

  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      TypeList,
      CommonTable
    }
  },
  __name: 'Survey',
  setup(__props) {

  
  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);
  const store = useStore()
  
  let html = ref('')
  let title = ref('')
  let titleName = ref('')
  let tableData = ref([])
  let form = ref({})
  let formRef = ref(null)
  let userInfoForm = ref([])

  onMounted(() => {
    showPage();
  })

  function showPage() {
    globalProperties.$API.StandardizedServicesDetail({
      type: 6
    }).then((res: any) => {
      title.value = res.data.data.title
      html.value = res.data.data.mainText;
      tableData.value = res.data.data.attachmentList || []
      titleName.value = `${ res.data.data.title } - ${ store.state.commoInfo.websiteName }`
    })
    globalProperties.$API.SatisfactionSurveyQueList().then((res: any) => {
      if(res.data.code == 200) {
        let data = [] as any;
        res.data.data.forEach((item: any, index: number) => {
          data.push({ ...item, key: 'answer' + index, rules: [{required:true,message:'请输入内容'}], length: 500 })
        })
        userInfoForm.value = data
      }
    })
  }
  
  function  handleSubmit({values, errors}: { [key: string]: any }) {
    if(errors) {
      return;
    }else {
     submitEvent();
    }
  }

  function resetClick() {
    (formRef.value as any).resetFields();
    (formRef.value as any).clearValidate();
  }

  function submitEvent() {
    let data = [] as any;
    userInfoForm.value.forEach((item: any, index: number) => {
      data.push({ title: item.title, answer: (form.value as any)['answer' + index]})
    })

    globalProperties.$API.SubmitSatisfactionSurvey(data).then((res: any) => {
      if(res.data.code == 200) {
        globalProperties.$message.success('操作成功');
        resetClick();
      }else if(res.data.code == 401){
        globalProperties.$message.error('登录已失效，麻烦您重新登录，即将为您跳转到登录页面...')
        router.push('/login')
      }else {
        globalProperties.$message.error(res.data.msg || '操作失败')
      }
    })
  }
 
return (_ctx: any,_cache: any) => {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _directive_wechat_title = _resolveDirective("wechat-title")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TypeList, {
      list: (_unref(CONST).commonType as any).keySix
    }, null, 8, ["list"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_unref(title)), 1)
      ]),
      _createVNode(_component_a_divider),
      _createElementVNode("div", {
        class: "w-e-text",
        innerHTML: _unref(html)
      }, null, 8, _hoisted_4),
      (_unref(tableData).length > 0)
        ? (_openBlock(), _createBlock(CommonTable, {
            key: 0,
            columns: (_unref(CONST) as any).commonFileTable,
            tableData: _unref(tableData)
          }, {
            options: _withCtx(({ record }) => [
              _createVNode(_component_a_button, { type: "text" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("下载")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["columns", "tableData"]))
        : _createCommentVNode("", true),
      _createVNode(_component_a_form, {
        class: "form_panel",
        model: _unref(form),
        ref_key: "formRef",
        ref: formRef,
        layout: "vertical",
        onSubmit: handleSubmit
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userInfoForm) as any, (item) => {
            return (_openBlock(), _createBlock(_component_a_form_item, {
              key: item.key,
              field: item.key,
              label: item.title,
              rules: item.rules,
              "hide-asterisk": true,
              "validate-trigger": ['change','input']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  modelValue: (_unref(form) as any)[item.key],
                  "onUpdate:modelValue": ($event: any) => (((_unref(form) as any)[item.key]) = $event),
                  placeholder: "请输入内容",
                  "show-word-limit": "",
                  "max-length": item.length,
                  "allow-clear": ""
                }, null, 8, ["modelValue", "onUpdate:modelValue", "max-length"])
              ]),
              _: 2
            }, 1032, ["field", "label", "rules"]))
          }), 128)),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                "html-type": "submit"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("提交")
                ])),
                _: 1
              }),
              _createVNode(_component_a_button, { onClick: resetClick }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("重置")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ])), [
    [_directive_wechat_title, _unref(titleName)]
  ])
}
}

})